<template>
	<div class="app-container">
		<div class="filter-container mt-3">
			<el-date-picker v-model="searchData.date_range" type="daterange" align="right" unlink-panels :range-separator="$t('general.to')" :start-placeholder="$t('general.date_start')" :end-placeholder="$t('general.date_end')" :picker-options="pickerOptions" class="filter-item" value-format="yyyy-MM-dd" :disabled="loading" @change="saveData()"></el-date-picker>
			
			<el-select v-model="searchData.branch_id" class="filter-item" filterable :placeholder="$t('admin_general.table_branch')" clearable @change="saveData()">
				<el-option v-for="(item,index) in branchList" :key="index" :label="item.name" :value="item.id" :disabled="loading"></el-option>
			</el-select>
			
			<el-button class="filter-item" type="primary" icon="el-icon-search" @click="initial()" :loading="buttonloading">{{$t('button.search')}}</el-button>
			
			<el-button @click.native.prevent="forceAll()" type="danger" size="small" :loading="buttonloading" icon="el-icon-folder-delete" class="float-right filter-item" v-if="permissionList.includes(permission.forceAll)">{{$t('button.force_all_logout')}}</el-button>
			<el-button @click.native.prevent="forceMultiple()" type="info" size="small" :loading="buttonloading" icon="el-icon-document-delete" class="float-right filter-item" v-if="permissionList.includes(permission.forceMultiple)">{{$t('button.force_multiple_logout')}}</el-button>
		</div>
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row @selection-change="selectionData">
			<el-table-column type="selection" width="55"></el-table-column>
			
			<el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
				<template slot="header">
					<p class="search-label">{{title.label}}</p>
					<b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
				</template>
			</el-table-column>
			<el-table-column type="expand">
				<template slot-scope="scope">
					<el-button type="info" :loading="buttonloading" @click="getInfoRow(scope.row.master_id)" v-if="permissionList.includes(permission.info)" icon="el-icon-view">{{$t('button.info')}}</el-button>
					<el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.master_id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
					<el-button type="success" :loading="buttonloading" @click="getPermissionRow(scope.row.master_id)" v-if="permissionList.includes(permission.permission)" icon="el-icon-star-off">{{$t('button.permission')}}</el-button>
					<el-button type="warning" :loading="buttonloading" @click="logRow(scope.row.master_id)" v-if="permissionList.includes(permission.log)" icon="el-icon-document">{{$t('button.log')}}</el-button>
				</template>
			</el-table-column>
		</el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"  @pagination="pagination"/>

		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

		<b-modal id="infoRow" :title="$t('menu.admin_list_info')" size="lg" hide-footer>
			<b-tabs class="account-tab">
				<b-tab active>
					<template slot="title">
						<div class="tab-title">
							<span>{{$t('admin_general.table_step_1')}}</span>
							<br/>
							<span>{{$t('admin_general.table_account_info')}}</span>
						</div>
					</template>

					<b-row class="p-2">
						<b-col cols="12" class="my-2">
							<h4><ins>{{$t('admin_general.table_account_info')}}</ins></h4>
						</b-col>

						<b-col cols="12" lg="6">
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_user_id')}}</template>
								<label>{{dataList.login}}</label>
							</b-form-group>
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_account_status')}}</template>
								<label>{{dataList.status}}</label>
							</b-form-group>
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_account_job')}}</template>
								<label>{{dataList.account_job}}</label>
							</b-form-group>
						</b-col>

						<b-col cols="12" lg="6">
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_last_ip')}}</template>
								<label>{{dataList.last_ip}}</label>
							</b-form-group>
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_last_ip2')}}</template>
								<label>{{dataList.last_ip2}}</label>
							</b-form-group>
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_last_login')}}</template>
								<label>{{dataList.last_login}}</label>
							</b-form-group>
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_last_login2')}}</template>
								<label>{{dataList.last_login2}}</label>
							</b-form-group>
						</b-col>
					</b-row>
				</b-tab>
				<b-tab>
					<template slot="title">
						<div class="tab-title">
							<span>{{$t('admin_general.table_step_2')}}</span>
							<br/>
							<span>{{$t('admin_general.table_personal_info')}}</span>
						</div>
					</template>

					<b-row class="p-2">
						<b-col cols="12" class="my-2">
							<h4><ins>{{$t('admin_general.table_personal_info')}}</ins></h4>
						</b-col>

						<b-col cols="12" lg="6">
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_full_name')}}</template>
								<label>{{dataList.name}}</label>
							</b-form-group>
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_email')}}</template>
								<label>{{dataList.email}}</label>
							</b-form-group>
						</b-col>

						<b-col cols="12" lg="6">
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_phone_mobile')}}</template>
								<label>{{dataList.phone_mobile}}</label>
							</b-form-group>
							<b-form-group label-cols="12" label-cols-lg="4">
								<template slot="label">{{$t('admin_general.table_created_at')}}</template>
								<label>{{dataList.created_at}}</label>
							</b-form-group>
						</b-col>
						
						<b-col cols="12" lg="12" v-if="this.branchSelect.includes(dataList.job_id)">
							<b-form-group label-cols="12" label-cols-lg="12">
								<template slot="label">{{$t('admin_general.table_belong_branch')}}</template>
								<el-checkbox-group v-model="dataList.branch_id" class="mt-2" :max="(dataList.job_id == '3') ? 1 : 99 ">
									<el-checkbox v-for="(item,index) in branchList" :key="index" :label="item.id" >{{item.name}}</el-checkbox>
								</el-checkbox-group>
							</b-form-group>
						</b-col>
					</b-row>
				</b-tab>
			</b-tabs>
		</b-modal>

		<b-modal id="editRow" :title="$t('menu.member_list_edit')" size="lg" hide-footer no-enforce-focus>
			<el-form @keyup.enter.native="editRow()">
				<b-tabs class="account-tab" v-model="tabIndex">
					<b-tab active>
						<template slot="title">
							<div class="tab-title">
								<span>{{$t('admin_general.table_step_1')}}</span>
								<br/>
								<span>{{$t('admin_general.table_account_info')}}</span>
							</div>
						</template>

						<b-row class="p-2">
							<b-col cols="12" class="my-2">
								<h4><ins>{{$t('admin_general.table_account_info')}}</ins></h4>
							</b-col>

							<b-col cols="12" lg="6">
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_user_id')}}</template>
									<b-form-input v-model="dataList.login"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_account_status')}}</template>
									<el-select filterable v-model="dataList.status" :placeholder="$t('msg.msg_select')" class="w-100">
										<el-option value='normal' :label="$t('admin_general.table_normal')"></el-option>
										<el-option value='inactive' :label="$t('admin_general.table_inactive')"></el-option>
										<el-option value='suspended' :label="$t('admin_general.table_suspended')"></el-option>
									</el-select>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_affiliate_id')}}</template>
									<label>{{dataList.affiliate_id}}</label>
								</b-form-group>
							</b-col>

							<b-col cols="12" lg="6">
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_password')}}</template>
									<b-form-input v-model="dataList.password" type="password"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_confirm_password')}}</template>
									<b-form-input v-model="dataList.confirm_password" type="password"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_security_password')}}</template>
									<b-form-input v-model="dataList.security" type="password"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_confirm_security_password')}}</template>
									<b-form-input v-model="dataList.confirm_security" type="password"></b-form-input>
								</b-form-group>
							</b-col>
							<b-col cols="12">
								<el-button type="primary" @click="tabIndex++" class="float-right">{{$t('admin_general.table_next')}}</el-button>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab>
						<template slot="title">
							<div class="tab-title">
								<span>{{$t('admin_general.table_step_2')}}</span>
								<br/>
								<span>{{$t('admin_general.table_personal_info')}}</span>
							</div>
						</template>

						<b-row class="p-2">
							<b-col cols="12" class="my-2">
								<h4><ins>{{$t('admin_general.table_personal_info')}}</ins></h4>
							</b-col>

							<b-col cols="12" lg="6">
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_full_name')}}</template>
									<b-form-input v-model="dataList.name"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_email')}}</template>
									<b-form-input v-model="dataList.email"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_country')}}</template>
									<el-select filterable v-model="dataList.country_id" :placeholder="$t('msg.msg_select')" class="w-100">
										<el-option v-for="(list,index) in countryList" :key="index" :value='list.id' :label="list.name">{{list.name}}</el-option>
									</el-select>
								</b-form-group>
							</b-col>

							<b-col cols="12" lg="6">
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_phone_mobile')}}</template>
									<b-form-input v-model="dataList.phone_mobile"></b-form-input>
								</b-form-group>
								<b-form-group label-cols="12" label-cols-lg="4">
									<template slot="label">{{$t('admin_general.table_created_at')}}</template>
									<label>{{dataList.created_at}}</label>
								</b-form-group>
							</b-col>
							
							<b-col cols="12" lg="12" v-if="this.branchSelect.includes(dataList.job_id)">
								<b-form-group label-cols="12" label-cols-lg="12">
									<template slot="label">{{$t('admin_general.table_belong_branch')}}</template>
									<el-checkbox-group v-model="dataList.branch_id" class="mt-2" :max="(dataList.job_id == '3') ? 1 : 99 ">
										<el-checkbox v-for="(item,index) in branchList" :key="index" :label="item.id" >{{item.name}}</el-checkbox>
									</el-checkbox-group>
								</b-form-group>
							</b-col>
							
							<b-col cols="12">
								<el-button type="primary" @click="tabIndex--" class="float-left">{{$t('admin_general.table_previous')}}</el-button>
								<el-button type="primary" @click="editRow()" class="float-right" :loading="buttonloading">{{$t('button.submit')}}</el-button>
							</b-col>
						</b-row>
					</b-tab>
				</b-tabs>
			</el-form>
		</b-modal>

		<b-modal id="permissionRow" :title="$t('menu.admin_list_permission')" hide-footer size="xl">
			<el-form label-width="80px" class="mt-3" @keyup.enter.native="permissionRow">
				<div class="row">
					<el-container>
						<el-header style="height:30px;">
							<el-checkbox v-model="checkAll" @change="handleCheckAllChange">{{$t('button.check_all')}}</el-checkbox>
						</el-header>
						<el-main>
							<div class="col-12" v-for="(item, index) in jobPermissionList" :item="item" :key="index">
								<el-checkbox class="job_checkbox" :label="item.category" @change="handleCheckAllChangePart($event, item.category)">{{$t('menu.'+item.category)}}</el-checkbox>
								<div style="margin: 15px 0;"></div>
								<div class="row">
									<div v-for="permissionSub in item.sub_list" :key="permissionSub.id" class="col-4 col-sm-4 col-md-3">
										<el-checkbox-group v-model="jobPermissionDetail">
											<el-checkbox :label="permissionSub.id" >{{$t('menu.'+permissionSub.file)}}</el-checkbox>
										</el-checkbox-group>
									</div>
								</div>
							</div>
						</el-main>
						
						<el-footer align="center">
							<el-button size="medium" @click="$bvModal.hide('permissionRow')">{{$t('button.cancel')}}</el-button>
							<el-button size="medium" type="primary" @click="permissionRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
						</el-footer>
					</el-container>
				</div>
			</el-form>
		</b-modal>
		
		<b-modal id="logRow" :title="$t('menu.admin_list_log')" hide-footer size="lg">
			<div class="filter-container mt-3">
				<el-date-picker v-model="logSearchData.date_range" popper-class="hx-h11" type="daterange" align="right" unlink-panels :range-separator="$t('general.to')" :start-placeholder="$t('general.date_start')" :end-placeholder="$t('general.date_end')" :picker-options="pickerOptions" class="filter-item" value-format="yyyy-MM-dd" :disabled="loading"></el-date-picker>
				
				<el-button class="filter-item" type="primary" icon="el-icon-search" @click="logAjaxTable()" :loading="buttonloading">{{$t('button.search')}}</el-button>
			</div>
		
			<el-table :data="logTableData" v-loading="loading" style="width: 100%;"  class="mt-3" ref="tableLog" border fit highlight-current-row>
				<el-table-column v-for="title in logAjaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
					<template slot="header">
						<p class="search-label">{{title.label}}</p>
						<b-form-input :placeholder="title.label" @keyup.enter.native="logAjaxTable" v-model="logSearchData[title.prop]"></b-form-input>
					</template>
				</el-table-column>
			</el-table>

			<pagination v-show="total > 0" :total="logTotal" :page.sync="logListQuery.page" :limit.sync="logListQuery.limit" @pagination="logPagination"/>
        </b-modal>   
	</div>
</template>

<script>
import { getLocalStorage } from "@/system/store/localstorage";
import { postMethod } from "@/system/model/post/post";
import { getDAES, getEAES } from "@/system/library/security";
import pagination from "@/components/pagination";
import backtotop from "@/components/backtotop";

let searchForm = {
	pagination: 1,
	limit: 10,
	id: "",
	master_id: "",
	created_at: "",
	date_range: "",
	login: "",
	job_name: "",
	branch_id: "",
	email: "",
	last_ip: "",
	last_ip2: "",
	last_login: "",
	last_login2: "",
	is_special: "",
	status: ""
}

export default {
	components: { pagination, backtotop },
	inject: ["preloader"],
	data() {
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			logTableData: [],
			total: 0,
			logTotal: 0,
			errors: [],
			branchSelect:['3','7'],
			submitForm: {
				master_id: ""
			},
			postData: {
				data: "",
				language: ""
			},
			searchData: Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles: [{
				prop: "master_id",
				label: this.$t("admin_general.table_uid"),
				width: "50"
			},{
				prop: "created_at",
				label: this.$t("admin_general.table_created_at"),
				width: "100"
			},{
				prop: "login",
				label: this.$t("admin_general.table_username"),
				width: "100"
			},{
				prop: "job_name",
				label: this.$t("admin_general.table_job"),
				width: "100"
			},{
				prop: "icpass",
				label: this.$t("admin_general.table_ic"),
				width: "100"
			},{
				prop: "name",
				label: this.$t("admin_general.table_name"),
				width: "100"
			},{
				prop: "email",
				label: this.$t("admin_general.table_email"),
				width: "100"
			},{
				prop: "last_ip",
				label: this.$t("admin_general.table_last_ip"),
				width: "100"
			},{
				prop: "last_ip2",
				label: this.$t("admin_general.table_last_ip2"),
				width: "100"
			},{
				prop: "last_login",
				label: this.$t("admin_general.table_last_login"),
				width: "100"
			},{
				prop: "last_login2",
				label: this.$t("admin_general.table_last_login2"),
				width: "100"
			},{
				prop: "status",
				label: this.$t("admin_general.table_status"),
				width: "60"
			}],
			branchList: [],
			dataList: {
				login:'',
				status:'',
				password:'',
				confirm_password:'',
				security:'',
				confirm_security:'',
				name:'',
				email:'',
				phone_mobile:'',
				country_id:'',
				branch_id:'',
				my_security:''
			},
			forceLogOutList:{
				ids: [],
				security: ''
			},
			permission: {
				index:'NJEJWJ',
				ajaxTable:'5FUJ21',
				info:'D8AY7F',
				edit: "QAWCS3",
				DBedit: "O96ZWP",
				permission: 'H7TQKS',
				DBpermission: 'W2AV1J',
				forceAll: '296IAX',
				forceMultiple: '66ODV1',
				log: '6S7PPM'
			},
			permissionList: [],
			jobPermissionDetail:[],
			jobPermissionList:[],
			postPermission:{
				id:'',
				permission:[]
			},
			isIndeterminateAll: false,
			isIndeterminate: false,
			checkAll: false,
			tabIndex:1,
			countryList:[],
			multipleSelection:[],
			logSearchData: {
				pagination: 1,
				limit: 10,
				id: "",
				master_id: "",
				by_master_id: "",
				created_at: "",
				remark: "",
				ip: "",
				date_range: ""
			},
			logListQuery: {
				page: 1,
				limit: 10
			},
			logAjaxTitles: [{
				prop: "id",
				label: this.$t("admin_general.table_uid"),
				width: "50"
			},{
				prop: "created_at",
				label: this.$t("admin_general.table_created_at"),
				width: "100"
			},{
				prop: "by_master_id",
				label: this.$t("admin_general.table_handler"),
				width: "100"
			},{
				prop: "ip",
				label: this.$t("admin_general.table_ip"),
				width: "100"
			},{
				prop: "remark",
				label: this.$t("admin_general.table_remark"),
				width: "100"
			}],
			pickerOptions: {
				shortcuts: [{
					text: this.$t('general.recent_week'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_one_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_three_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
            }
		};
	},
	methods: {
		getInitial(){
			if(this.permissionList.includes(this.permission.index)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod("admin/lists", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.branchList = data.branchList;
						self.initial();
					}
				});
				
				this.saveData();
			}
		},initial() {
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod("admin/lists/ajaxTable", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
				
				this.saveData();
			}
		},
		getInfoRow(master_id){
			if(this.permissionList.includes(this.permission.info)){
				this.buttonloading = true;
				this.submitForm.master_id = master_id;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod("admin/lists/info", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.dataList = data.thisDetail;
						self.$bvModal.show("infoRow");
					}
					self.buttonloading = false;
				});
			}
		},
		getEditRow(master_id) {
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.master_id = master_id;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod("admin/lists/edit", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.dataList = data.thisDetail;
						self.countryList = data.countryList;
						self.$bvModal.show("editRow");
					}
					self.buttonloading = false;
				});
			}
		},
		editRow() {
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
			
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.dataList.my_security = value;
					this.postData.data = "";
					this.postData.data = getEAES(JSON.stringify(this.dataList));
					var text = "";
					var self = this;
					var result = postMethod("admin/lists/DBedit", this.postData);

					result.then(function(value) {
						var data = JSON.parse(getDAES(value.data));

						if (value.valid) {
							self.$message({
								message: data.returnMsg,
								type: "success"
							});
							
							self.$bvModal.hide("editRow");
							self.initial();
						} else {
							data.returnMsg.forEach(function(value) {
								text += value + "<br/>";
							});

							self.$method.popupErrorMessage(self, text);
						}

						self.buttonloading = false;
						self.preloader(false);
					});
				}).catch(() => {
					this.buttonloading = false;    
					this.preloader(false);						
				});
			}
		},
		getPermissionRow(master_id) {
			if(this.permissionList.includes(this.permission.permission)){
				this.buttonloading = true;
				this.submitForm.master_id = master_id;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod("admin/lists/permission", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.jobPermissionDetail = data.thisDetail.permission;
						self.jobPermissionList = data.permissionList;
						self.$bvModal.show("permissionRow");
					}
					self.buttonloading = false;
				});
			}
		},
		permissionRow() {
			if(this.permissionList.includes(this.permission.DBpermission)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = "";
				this.postPermission.master_id = this.submitForm.master_id;
				this.postPermission.permission = this.jobPermissionDetail;
				this.postData.data = getEAES(JSON.stringify(this.postPermission));
				var text = "";
				var self = this;
				var result = postMethod("admin/lists/DBpermission", this.postData);
					result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.$message({
							message: data.returnMsg,
							type: "success"
						});
					} else {
						data.returnMsg.forEach(function(value) {
							text += value + "<br/>";
						});

						self.$method.popupErrorMessage(self, text);
					}

					self.buttonloading = false;
					self.$bvModal.hide("permissionRow");
					self.initial();
					self.preloader(false);
				});
			}
		},logRow(id){
			if(this.permissionList.includes(this.permission.log)){
				this.buttonloading = true;
				this.logSearchData.master_id = id;
				this.logAjaxTable();
				this.$bvModal.show('logRow');
			}
		},logAjaxTable(){
			if(this.permissionList.includes(this.permission.log)){
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.logSearchData));
				var self = this;
				
				var result = postMethod('admin/lists/logAjaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.logTableData = data.datatable.data;
						self.logTotal = parseInt(data.datatable.total_number);
						self.logListQuery.page = parseInt(data.datatable.current_pagination);
						self.logListQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
					self.buttonloading = false;
				});
			}
		},forceAll(){
			if(this.permissionList.includes(this.permission.forceAll)){
				this.preloader(true);
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.forceLogOutList.security = value;
					
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.forceLogOutList));
					
					var text = '';
					var self = this;
					var result = postMethod('admin/lists/DBforceAll',this.postData);

					result.then(function(value){
						var data = JSON.parse(getDAES(value.data));

						if(value.valid){
							self.$message({
								message: data.returnMsg,
								type: 'success'
							});
							
							self.initial();
						}else{					
							data.returnMsg.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.$method.popupErrorMessage(self,text);
						}
						
						self.buttonloading = false;
						self.preloader(false);
					});
				}).catch(() => {
					this.buttonloading = false;    
					this.preloader(false);						
				});
			}
		},forceMultiple(){
			this.forceLogOutList.ids = [];
			
			if(this.multipleSelection.length >= 1){
				this.forceLogOutList.ids = this.multipleSelection;
				this.buttonloading = true;
				this.preloader(true);
				
				if(this.permissionList.includes(this.permission.forceMultiple)){
					this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.prompt'), {
						confirmButtonText: this.$t('button.confirm'),
						cancelButtonText: this.$t('button.cancel'),
						inputPlaceholder: this.$t('msg.msg_security_password'),
						inputType: 'password'
					}).then(({ value }) => {
						this.forceLogOutList.security = value;
						
						this.postData.data = '';
						this.postData.data = getEAES(JSON.stringify(this.forceLogOutList));
						
						var text = '';
						var self = this;
						var result = postMethod('admin/lists/DBforceMultiple',this.postData);

						result.then(function(value){
							var data = JSON.parse(getDAES(value.data));

							if(value.valid){
								self.$message({
									message: data.returnMsg,
									type: 'success'
								});
								
								self.initial();
							}else{					
								data.returnMsg.forEach(function (value){
									text+= value+"<br/>";
								});
								
								self.$method.popupErrorMessage(self,text);
							}
							
							self.buttonloading = false;
							self.preloader(false);
						});
					}).catch(() => {
						this.buttonloading = false;    
						this.preloader(false);						
					});
				}
			}else{
				this.$message.error(this.$t('error.msg_checkbox_select'));
			}
		},handleCheckAllChange(val) {
			this.jobPermissionDetail = [];
			if(val){
				var self = this;
				this.jobPermissionList.forEach(function(value){
					value.sub_list.forEach(function(value){
						self.jobPermissionDetail.push(value.id);
					});
				});
			}
		},handleCheckAllChangePart(val, id){
			var valList = [];
			var self = this;
			
			this.jobPermissionList.forEach(function(value){
				if(value.category == id){
					value.sub_list.forEach(function(value){
						valList.push(value.id);
					});
				}
			});

			if(val === true){
				valList.forEach(function(value){
					if(!self.jobPermissionDetail.includes(value)){
						self.jobPermissionDetail.push(value);
					}
				});
			}else{
				valList.forEach(function(value){
					var index = self.jobPermissionDetail.indexOf(value);
					if(index > -1){
						self.jobPermissionDetail.splice(index, 1);
					}
				});

			}
		},
		pagination() {
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},logPagination(){
            this.logSearchData.pagination = this.logListQuery.page; 
			this.logSearchData.limit = this.logListQuery.limit;
			this.logAjaxTable();
		},expandRow(row) {
			this.$refs.tableTest.toggleRowExpansion(row);
		},expandChange(row, expandedRows) {
			if (expandedRows.length > 1) {
				this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
			}	
		},selectionData(val){
			var self = this;
			if(val.length >= 1){
				val.forEach(function (value){
					self.multipleSelection.push(value.master_id);
				});
			}else{
				this.multipleSelection = [];
			}
		},saveData(){
			searchForm = this.searchData;
		}
	},
	created: function() {
		var currentLang = getLocalStorage("currentLang") ? getLocalStorage("currentLang") : "cn";
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.getInitial();
	}
};
</script>